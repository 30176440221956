<template>
  <v-app id="inspire" class="grey lighten-3">
    
    <v-navigation-drawer v-model="drawer" color='#37474F' width="350" app left dark>
      <v-list
        dense
      >
        <v-list-item>
          
          <v-list-item-content class="title">
            เมนูจัดการ
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="mb-2" />

      <v-list nav>
        <template v-for="item in mainmenu.items">
          <v-list-group
            v-if="item.children"
            :key="item.name"
            :prepend-icon="item.icon"
            color="white"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.text"
              link 
              :to="child.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
              v-else
              :key="item.text"
              link 
              :to="item.route"
          >
            <v-list-item-content>
              <v-list-item-title class="-1 text-wrap">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>        
      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar app color="#37474F" dark absolute flat>
      
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ $t('layout.system_name') }}</span>
      </v-toolbar-title>

      <v-spacer />

      <v-btn text v-show="false">
        <v-icon>mdi-cog</v-icon>
        <span>การตั้งค่า</span>
      </v-btn>
      <span>ผู้เข้าใช้งาน: {{ userFirstname }}</span>
      <v-btn text @click="logout">
        <v-icon>mdi-logout</v-icon>
        <span>ออกจากระบบ</span>
      </v-btn>
    </v-app-bar>
    <!-- <v-content> -->
    <v-main>
      <v-container fluid>
        <slot></slot>
      </v-container>
    </v-main>
    <!-- </v-content> -->

    <v-overlay :value="this.$store.getters.loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
  
</template>
<script>
// import Utils from "@/plugins/Utils";
import Api from "@/api/Api";

export default {
  data: () => ({
    storeUser: null,
    userFirstname: "",
    drawer: null,
    overlayLoad: false,
    mainmenu: {
      title: "Home",
      icon: "mdi-home",
      items: [
        // { text: "หน้าหลัก", route: "/", auth: [1,2,3] },
        // { text: "งานขาย", route: "/sale/dailylist", auth: [1,2,3] },
        // //{ text: "test page 1", route: "/testpage", auth: [1,2,3] },
        // {
        //   text: "งานเบิกจ่าย",
        //   auth: [1,2],
        //   children: [
        //     { text: "เบิกน้ำ", route: "/water/takelist", auth: [1,2] },
        //     { text: "เบิกบรรจุภัณฑ์", route: "/package/takelist", auth: [1,2] },
        //   ]
        // },
        // { text: "งานสต๊อก", route: "/stock", auth: [1,2] },
        // { text: "คืนสินค้าเข้าสต๊อก", route: "/water/return", auth: [1,2] },
        // { text: "รายการรายรับรายจ่าย", route: "/income", auth: [1,2] },
        // { icon: "mdi-chart-areaspline",
        //   text: "รายงาน",
        //   auth: [1,2,3],
        //   children: [
        //     { icon: "mdi-chart-areaspline", text: "รายงานสรุปยอดขายประจำวัน", route: "/report/daily", auth: [1,2,3] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานสรุปรายรับ-รายจ่ายประจำเดือน", route: "/report/monthly", auth: [1,2] },
        //   ]
        // },
        // {
        //   icon: "mdi-chart-areaspline",
        //   text: "รายงาน",
        //   auth: [1,2,3],
        //   children: [
        //     { icon: "mdi-chart-areaspline", text: "รายงานบิลค้างของลูกค้า", route: "/report/customerdebt", auth: [1,2,3] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานยอดขายน้ำแข็งพนักงานประจำเดือน", route: "/report/employeecommission", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานขายหน้าลานประจำวัน", route: "/report/summaryincomefrontdaily", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานสรุปยอดขายรายวัน", route: "/report/summaryincomedaily", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานสรุปรายได้ประจำเดือน", route: "/report/summaryincome", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานสรุปรายได้ประจำปี", route: "/report/summaryincomeyear", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานขายส่งประจำเดือน", route: "/report/showreportsalemonthlyform", auth: [1,2] },
        //     { icon: "mdi-chart-areaspline", text: "รายงานถังน้ำแข็ง", route: "/report/tank", auth: [1,2] }
        //   ]
        // },
        // { text: "รายการงาน", route: "/request/list", auth: [1,2] },
        // { text: "รายการแจ้งงาน", route: "/request/all", auth: [1,2] },
        // { text: "รายการงานที่ได้รับมอบหมาย", route: "/assign/list", auth: [1,2] },
        // { text: "แจ้งงาน", route: "/job/request", auth: [1,2] },
        // { text: "บุคลากร", route: "/staff", auth: [1,2] },
        // { text: "สาขาวิชา", route: "/school", auth: [1] },
      ]
    }
  }),
  props: {
    source: String
  },
  created() {
    // this.$store.commit('setLoading', false)
    this.storeUser = this.$store.getters.user
  },
  mounted() {
    // console.log(this.$store.getters.user)
    this.userFirstname = this.storeUser.firstname
    if(this.$store.getters.menu) {
      // console.log('menu store')
      this.mainmenu.items = this.$store.getters.menu
    } else {
      // console.log('menu api')
      this.getMenu();
    }
  },
  methods: {
    // checkPermission(type, auth){
    //   return Utils.checkPermission(type, auth)
    // },
    async getMenu() {
      try {
        this.tableLoading = true

        const req = {
          params: {
            group_id: this.storeUser.user_group
          }
        }

        // console.log(req)
        const res = (await Api().get("/menu", req)).data;

        if (res.status == 'success') {
          // console.log('data menu', res);
          this.mainmenu.items = res.data
          this.$store.commit('setMenu', res.data)
        } else {
          console.log(res.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    logout() {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
};
</script>
