import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/school',
    name: 'SchoolList',
    component: () => import('@/views/Admin/School/ListSchool.vue')
  },
  {
    path: '/school/add',
    name: 'SchoolAdd',
    component: () => import('@/views/Admin/School/AddSchool.vue')
  },
  {
    path: '/school/edit/:schoolId',
    name: 'SchoolEdit',
    component: () => import('@/views/Admin/School/EditSchool.vue'),
    props: true
  },
  {
    path: '/staff',
    name: 'StaffList',
    component: () => import('@/views/Admin/Staff/ListStaff.vue')
  },
  {
    path: '/staff/add',
    name: 'StaffAdd',
    component: () => import('@/views/Admin/Staff/AddStaff.vue')
  },
  {
    path: '/staff/edit/:staffId',
    name: 'StaffEdit',
    component: () => import('@/views/Admin/Staff/EditStaff.vue'),
    props: true
  },
  {
    path: '/request/list',
    name: 'ListRequest',
    component: () => import('@/views/Job/ListRequestJob.vue'),
  },{
    path: '/assign/list',
    name: 'ListAssignJob',
    component: () => import('@/views/Job/ListAssignJob.vue'),
  },
  {
    path: '/request/all',
    name: 'ListRequestAll',
    component: () => import('@/views/Job/ListAllRequestJob.vue'),
  },
  {
    path: '/job/request',
    name: 'JobRequest',
    component: () => import('@/views/Job/RequestJob.vue'),
  },
  {
    path: '/job/project/request',
    name: 'JobProjectRequest',
    component: () => import('@/views/Job/RequestJobProject.vue'),
  },
  {
    path: '/job/request/edit/:jobId',
    name: 'EditJobRequest',
    component: () => import('@/views/Job/EditRequestJob.vue'),
    props: true
  },
  {
    path: '/job/project/request/edit/:jobId',
    name: 'EditJobProjectRequest',
    component: () => import('@/views/Job/EditRequestJobProject.vue'),
    props: true
  },
  {
    path: '/job/travelling/request',
    name: 'JobTravellingRequest',
    component: () => import('@/views/Job/RequestJobTravelling.vue'),
  },
  {
    path: '/job/assign/:jobId',
    name: 'AssignJob',
    component: () => import('@/views/Job/AssignJob.vue'),
    props: true
  },
  {
    path: '/job/:jobId',
    name: 'DisplayJob',
    component: () => import('@/views/Job/DisplayJob.vue'),
    props: true
  },
  {
    path: '/job/assign/status/:jobId',
    name: 'UpdateStatusJob',
    component: () => import('@/views/Job/UpdateStatusJob.vue'),
    props: true
  },
  {
    path: '/upload/budget',
    name: 'UploadBudget',
    component: () => import('@/views/Budget/UploadBudget.vue'),
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () => import('@/views/Budget/DisplayBudget.vue'),
  },
  {
    path: '/plan',
    name: 'ListPlan',
    component: () => import('@/views/Work/ListPlan.vue'),
  },
  {
    path: '/research',
    name: 'ListResearch',
    component: () => import('@/views/Work/ListResearch.vue'),
  },
  {
    path: '/article',
    name: 'ListArticle',
    component: () => import('@/views/Work/ListArticle.vue'),
  },
  {
    path: '/plan/add',
    name: 'AddPlan',
    component: () => import('@/views/Work/AddPlanManage.vue'),
  },
  {
    path: '/project',
    name: 'ListProject',
    component: () => import('@/views/Work/ListProject.vue'),
  },
  {
    path: '/project/add',
    name: 'AddProject',
    component: () => import('@/views/Work/AddProject.vue'),
  },
  {
    path: '/research/add',
    name: 'AddResearch',
    component: () => import('@/views/Work/AddResearch.vue'),
  },
  {
    path: '/article/add',
    name: 'AddArticle',
    component: () => import('@/views/Work/AddArticle.vue'),
  },
  {
    path: '/plan/edit/:planId',
    name: 'PlanUpdate',
    component: () => import('@/views/Work/EditPlanManage.vue'),
    props: true
  },
  {
    path: '/project/edit/:projectId',
    name: 'ProjectUpdate',
    component: () => import('@/views/Work/EditProject.vue'),
    props: true
  },
  {
    path: '/research/edit/:researchId',
    name: 'ResearchUpdate',
    component: () => import('@/views/Work/EditResearch.vue'),
    props: true
  },
  {
    path: '/article/edit/:articleId',
    name: 'ArticleUpdate',
    component: () => import('@/views/Work/EditArticle.vue'),
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);

  if(authRequired){

    if(store.state.user == undefined || store.state.user == null){
      return next('/login');
    }

    // const req = {
    //   params: {
    //     username: store.state.user.username
    //   }
    // }
    // try {
    //   let res = (await Api().get("user/check", req)).data;
    //   if(res.status == 'error'){
    //     return next('/login');
    //   }
    // }catch (err) {
    //   return next('/login');
    // }
  }
  // console.log(store.state.isUserLoggedIn)
  const loggedIn = store.state.isUserLoggedIn

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})

export default router
