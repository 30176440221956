import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState()
  ],
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false,
    loading: false,
    menu: null
  },
  getters: {
    user: state => {
      return state.user
    },
    token: state => {
      return state.token
    },
    isUserLoggedIn: state => {
      return state.isUserLoggedIn
    },
    loading: state => {
      return state.loading
    },
    menu: state => {
      return state.menu
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      state.isUserLoggedIn = !!(token)
    },
    setUser (state, user) {
      state.user = user
    },
    logout (state) {
      state.token = null
      state.user = null
      state.isUserLoggedIn = false
      state.loading = false
      state.menu = null
    },
    setLoading(state, loading){
      state.loading = loading
    },
    setMenu(state, menu) {
      state.menu = menu
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    },
    logout ({commit}) {
      commit('logout')
    },
    setLoading ({commit}, loading) {
      commit('setLoading', loading)
    },
    setMenu ({commit}, menu) {
      commit('setMenu', menu)
    },
  }
})
