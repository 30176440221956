import axios from 'axios'
import store from '@/store'

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `${store.state.token}`
      // Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjEsImlhdCI6MTYwNzI2MTkxOSwiZXhwIjoxNjA3MjcyNzE5fQ.17Ksdm5193pRMw5-e0GCJZoQ3sxMGH2uDmcbjRZC19c"
    }
  })
}