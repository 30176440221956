import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import {
  sync
} from "vuex-router-sync";

sync(store, router)

Vue.config.productionTip = false

import layout from '@/components/Layout'
import confirm from '@/components/Confirm'

import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: 'กรุณากรอกข้อมูล',
})

extend('email', {
    ...email,
    message: 'กรอกข้อมูล email ไม่ถูกต้อง',
})

extend("decimal", {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'กรุณากรอกข้อมูลเฉพาะตัวเลขเท่านั้น'
      }
    };
  },
  message: 'กรุณากรอกข้อมูลเฉพาะตัวเลขเท่านั้น'
})


Vue.component('layout', layout)
Vue.component('Confirm', confirm)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
